import router from '@/router'
// eslint-disable-next-line object-curly-newline
import { getCurrentInstance, reactive, toRefs, watch } from '@vue/composition-api'
import { getUserData } from '@/utils/auth'

export const isObject = obj => typeof obj === 'object' && obj !== null

export const isToday = date => {
  const today = new Date()
  return (
  /* eslint-disable operator-linebreak */
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
  /* eslint-enable */
  )
}

const getRandomFromArray = array => array[Math.floor(Math.random() * array.length)]

// ? Light and Dark variant is not included
// prettier-ignore
export const getRandomBsVariant = () => getRandomFromArray(['primary', 'secondary', 'success', 'warning', 'danger', 'info'])

export const isDynamicRouteActive = route => {
  const { route: resolvedRoute } = router.resolve(route)
  return resolvedRoute.path === router.currentRoute.path
}

// Thanks: https://medium.com/better-programming/reactive-vue-routes-with-the-composition-api-18c1abd878d1
export const useRouter = () => {
  const vm = getCurrentInstance().proxy
  const state = reactive({
    route: vm.$route,
  })

  watch(
    () => vm.$route,
    r => {
      state.route = r
    },
  )

  return { ...toRefs(state), router: vm.$router }
}

/**
 * Formats a given date into a localized string based on the user's timezone.
 *
 * @param {Date|string} date - The date to format. Can be a Date object or a string that can be parsed into a Date.
 * @returns {string} The formatted date string.
 * @throws {Error} If the input date is invalid or the user's timezone is not available.
 */
export const formatDate = date => {
  if (!(date instanceof Date) && !Date.parse(date)) {
    throw new Error('Invalid date input')
  }

  const userData = getUserData()
  if (!userData.timezone || !userData.timezone.abbr) {
    throw new Error('User timezone not available')
  }

  return new Date(date || '').toLocaleString('en-US', {
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    timeZone: userData.timezone.abbr,
    hour12: false,
  })
}
