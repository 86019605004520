<template>
  <div>

    <b-overlay
      :show="$apollo.loading || mutationLoading"
      rounded="sm"
    >
      <!-- search input -->
      <div class="custom-search d-flex justify-content-start">
        <b-form-group
          class="form-inline"
          label="Search"
          label-size="sm"
        >
          <b-form-input
            v-model="searchTerm"
            class="d-inline-block mr-1"
            placeholder="Search meeting"
            type="text"
          />
          <b-button
            v-b-modal.scheduleMeetingModal
            variant="primary mr-1"
          >
            <feather-icon icon="ClockIcon" />
            Schedule Session
          </b-button>
          <b-button
            v-b-modal.addSlotModalold
            variant="outline-success mr-1"
          >
            <feather-icon icon="PlusIcon" />
            Add Slot
          </b-button>
        </b-form-group>
      </div>

      <!-- table -->
      <vue-good-table
        :columns="columns"
        :pagination-options="{
          enabled: true,
          perPage:pageLength
        }"
        :rows="rows"
        :search-options="{
          enabled: true,
          externalQuery: searchTerm }"
      >
        <template
          slot="table-row"
          slot-scope="props"
        >

          <!-- Column: Action -->
          <span
            v-if="props.column.field === 'action'"
            class="d-flex"
          >
            <b-button
              v-b-tooltip="'Update Links'"
              class="mr-50"
              size="sm"
              variant="outline-primary"
              @click="selectedRow = props.row.originalIndex; $bvModal.show('updateLinks')"
            >
              <feather-icon
                icon="LinkIcon"
              />
            </b-button>
            <!-- <b-button
              v-b-tooltip="'Add Participants'"
              size="sm"
              variant="outline-primary"
              @click="selectedRow = props.row.originalIndex; $bvModal.show('addParticipants')"
            >
              <feather-icon
                icon="UserPlusIcon"
              />
            </b-button> -->
          </span>

          <span v-else-if="props.column.field === 'buttons'">

            <b-button
              :href="prependHttps(props.row.meeting_link)"
              class="mb-25 mr-25"
              size="sm"
              target="_blank"
              :variant="props.row.meeting_link ? 'outline-primary' : 'outline-secondary'"
            >Join meeting</b-button>
            <b-button
              v-if="props.row.recording_link"
              :href="prependHttps(props.row.recording_link)"
              size="sm"
              target="blank"
              variant="outline-primary"
            >View recording</b-button>
          </span>

          <span v-else-if="props.column.label === 'Meeting'">

            {{ props.row.title }}<br> <b-badge
              variant="light-primary"
              class="mt-25"
            >{{ props.row.type }}</b-badge>
          </span>

          <span v-else-if="props.column.label === 'Time'">
            <span v-if="props.formattedRow[props.column.field] !== '-'">
              {{ props.formattedRow[props.column.field] }}
            </span>
            <b-button
              v-if="props.row.sessions_participanttables.length > 0 && props.row.sessions_participanttables[0].sessions_slots_tables.length > 0"
              size="sm"
              variant="outline-primary"
              @click="generateScheduleClock(); scheduleParticipantIds = props.row.sessions_participanttables; bookedbegindatetimestamp = props.row.begin_time; bookedenddatetimestamp = props.row.end_time; setAvailability(props.row); $bvModal.show('scheduleSlotModal')"
            >
              View Slots
            </b-button>
          </span>

          <span v-else-if="props.column.field === 'status'">
            <b-badge
              :variant="statusVariant(props.formattedRow[props.column.field])"
              class="mb-25"
            >{{ props.formattedRow[props.column.field] }}</b-badge>
          </span>

          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>
        <template
          #column-filter="{ column, updateFilters }"
        >
          <span v-if="column.label === 'Operation'">
            <treeselect
              :append-to-body="true"
              :normalizer="filterNormalizer"
              :options="operations"
              class="form-control p-0 border-0"
              placeholder="Select Phase, Subphase or Activities"
              style="border-collapse: separate"
              @input="(val) => updateFilters(column, val)"
            />
          </span>
        </template>
        <!-- pagination -->
        <template
          slot="pagination-bottom"
          slot-scope="props"
        >
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap ">
                Showing 1 to
              </span>
              <b-form-select
                v-model="pageLength"
                :options="['3','5','10']"
                class="mx-1"
                @input="(value)=>props.perPageChanged({currentPerPage:value})"
              />
              <span class="text-nowrap"> of {{ props.total }} entries </span>
            </div>
            <div>
              <b-pagination
                :per-page="pageLength"
                :total-rows="props.total"
                :value="1"
                class="mt-1 mb-0"
                first-number
                last-number
                next-class="next-item"
                prev-class="prev-item"
                @input="(value)=>props.pageChanged({currentPage:value})"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </b-overlay>
    <b-modal
      id="scheduleMeetingModal"
      ok-title="Save"
      no-close-on-backdrop
      size="lg"
      title="Schedule Session"
      @ok="addMeeting"
    >
      <!-- <b-row v-if="modalTab !== 0">
        <b-col>
          <b-button
            class="mb-1"
            variant="light"
            @click="modalTab = modalTab - 1"
          >
            <feather-icon
              icon="ChevronLeftIcon"
            />
            <span class="ml-1">Back</span>
          </b-button>
        </b-col>
      </b-row> -->
      <b-row
        v-if="modalTab === 0"
        class="justify-content-center"
      >
        <b-col
          md="4"
          class="px-1"
        >
          <div
            class="px-1 py-2"
            style="border: solid 1px #7367F0; border-radius: 10px; color: #7367F0; cursor: pointer;"
            @click="modalTab = 1; choice = 'slot'"
          >
            <div class="d-flex justify-content-center">
              <feather-icon
                icon="ListIcon"
                size="32"
              />
            </div>
            <h6 class="text-center text-primary mt-1">
              Choose Slot
            </h6>
          </div>
        </b-col>
        <b-col
          md="4"
          class="px-1"
        >
          <div
            class="px-1 py-2"
            style="border: solid 1px #7367F0; border-radius: 10px; color: #7367F0; cursor: pointer;"
            @click="modalTab = 1; choice = 'direct'; generateDirectClock();"
          >
            <div class="d-flex justify-content-center">
              <feather-icon
                icon="LayoutIcon"
                size="32"
              />
            </div>
            <h6 class="text-center text-primary mt-1">
              Direct Schedule
            </h6>
          </div>
        </b-col>
      </b-row>
      <b-row v-if="modalTab === 1 && choice === 'direct'">
        <b-col md="12">
          <b-form-group
            label="Title"
            label-for="title"
          >
            <b-form-input
              id="title"
              v-model="title"
              placeholder="Meeting title"
            />
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-form-group
            label="Description"
            label-for="description"
          >
            <b-form-textarea
              id="description"
              v-model="description"
              placeholder="Enter Description"
            />
          </b-form-group>
        </b-col>

        <b-col cols="6">
          <b-form-group
            label="Select Incubators"
            label-for="selectIncubators"
          >
            <v-select
              id="selectIncubators"
              v-model="selectedIncubator"
              :get-option-label="option => option.full_name"
              :options="incubators"
              :reduce="option => option.id"
              multiple
              placeholder="Select Incubators"
            />
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group
            label="Select Partners"
            label-for="selectPartners"
          >
            <v-select
              id="selectPartners"
              v-model="selectedPartner"
              :get-option-label="option => option.full_name"
              :options="partners"
              multiple
              placeholder="Select Partners"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          class="d-flex align-items-center mb-50"
        >
          <h6 class="mt-25">
            Request Slot:
          </h6>
        </b-col>

        <!-- start date and time -->
        <b-col
          cols="12"
          :class="`${requestSlot ? 'd-none' : ''}`"
        >
          <b-row>
            <b-col md="4">
              <b-form-group
                class="mb-2"
                label="Date"
                label-for="StartDate"
              >
                <flat-pickr
                  id="StartDate"
                  v-model="beginDate"
                  :config="{ enableTime: true, dateFormat: 'Y-m-d'}"
                  class="form-control"
                  placeholder="Select start date"
                />
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group
                class="mb-2"
                label="Start Time"
                label-for="StartTime"
              >
                <div
                  ref="bt"
                  class="timepicker-ui"
                >
                  <b-form-input
                    v-model="beginTime"
                    type="text"
                    class="timepicker-ui-input"
                    placeholder="Select end time"
                  />
                </div>
              </b-form-group>
            </b-col>
            <!-- end date and time -->
            <b-col md="4">
              <b-form-group
                class="mb-2"
                label="End Time"
                label-for="EndTime"
              >
                <div
                  ref="et"
                  class="timepicker-ui"
                >
                  <b-form-input
                    v-model="endTime"
                    type="text"
                    class="timepicker-ui-input"
                    placeholder="Select end time"
                  />
                </div>
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>
        <b-col
          cols="12"
          :class="`${!requestSlot ? 'd-none' : ''}`"
        >
          <b-row>
            <b-col md="4">
              <b-form-group
                class="mb-2"
                label="Date"
                label-for="StartDate"
              >
                <flat-pickr
                  id="StartDate"
                  v-model="beginDateR"
                  :config="{ enableTime: true, dateFormat: 'Y-m-d'}"
                  class="form-control"
                  placeholder="Select start date"
                />
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group
                class="mb-2"
                label="Start Time"
                label-for="StartTime"
              >
                <div
                  ref="btr"
                  class="timepicker-ui"
                >
                  <b-form-input
                    v-model="beginTimeR"
                    type="text"
                    class="timepicker-ui-input"
                    placeholder="Select end time"
                  />
                </div>
              </b-form-group>
            </b-col>
            <!-- end date and time -->
            <b-col md="3">
              <b-form-group
                class="mb-2"
                label="End Time"
                label-for="EndTime"
              >
                <div
                  ref="etr"
                  class="timepicker-ui"
                >
                  <b-form-input
                    v-model="endTimeR"
                    type="text"
                    class="timepicker-ui-input"
                    placeholder="Select end time"
                  />
                </div>
              </b-form-group>
            </b-col>
            <b-col md="2">
              <b-button
                variant="primary"
                block
                class="mt-2"
                @click="addRequestingSlot()"
              >
                <feather-icon
                  icon="PlusIcon"
                  class="mr-1"
                />
                <span>Add</span>
              </b-button>
            </b-col>
          </b-row>
          <h6 v-if="slotRequests.length > 0">
            Alternative Dates
          </h6>
          <b-row>
            <b-col cols="12">
              <b-badge
                v-for="(slot, i) in slotRequests"
                :key="i"
                variant="primary"
                class="mb-50 mr-50"
              >
                {{ `${new Date(slot.begin)
                  .toLocaleString('en-US', {
                    month: 'short',
                    day: 'numeric',
                    hour: 'numeric',
                    minute: 'numeric',
                    timeZone: gotUserData.timezone.abbr,
                    hour12: true,
                  })} - ${new Date(slot.end)
                  .toLocaleString('en-US', {
                    hour: 'numeric',
                    minute: 'numeric',
                    timeZone: gotUserData.timezone.abbr,
                    hour12: true,
                  })}` }}
                <feather-icon
                  style="cursor: pointer;"
                  icon="XIcon"
                  class="ml-1"
                  @click="removeRequestingSlot(i)"
                />
              </b-badge>
            </b-col>
          </b-row>
        </b-col>
        <b-col
          v-if="selectedIncubator"
          md="12"
        >
          <b-row>
            <b-col md="12">
              <h6>Additional Information (Optional)</h6>
            </b-col>
            <b-col
              v-if="!selectedExercise && !selectedMilestone"
            >
              <b-form-group
                label="Module"
                label-for="operation"
              >
                <treeselect
                  id="operation"
                  v-model="selectedOperation"
                  :normalizer="normalizer"
                  :options="operations"
                  placeholder="Select Module"
                />
              </b-form-group>
            </b-col>
            <b-col
              v-if="!selectedOperation && !selectedMilestone"
            >
              <b-form-group
                label="Exercises"
                label-for="exercises"
              >
                <treeselect
                  id="exercises"
                  v-model="selectedExercise"
                  :normalizer="exercisesnormalizer"
                  :options="exercises"
                  placeholder="Select Exercise"
                />
              </b-form-group>
            </b-col>
            <b-col
              v-if="selectedIncubator && selectedIncubator.length === 1 && !selectedOperation && !selectedExercise"
            >
              <b-form-group
                label="Milestone"
                label-for="milestone"
              >
                <treeselect
                  id="milestone"
                  v-model="selectedMilestone"
                  :normalizer="milestonesnormalizer"
                  :options="milestones"
                  placeholder="Select Milestone"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <div v-if="modalTab === 1 && choice === 'slot'">
        <b-row>
          <b-col md="12">
            <b-form-group
              label="Select Incubator or Partner"
              label-for="selectIncubatorPartners"
            >
              <v-select
                id="selectIncubatorPartners"
                v-model="incubatororpartner"
                :options="['Incubator','Partner','Mentor']"
                placeholder="Select Incubator, Partner or Mentor"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row v-if="incubatororpartner === 'Incubator'">
          <b-col md="12">
            <b-form-group
              label="Select Incubator"
              label-for="selectedIncubator"
            >
              <v-select
                id="selectedIncubator"
                v-model="selectedIncubator"
                :get-option-label="option => option.full_name"
                :options="incubators"
                :reduce="option => option"
                placeholder="Select Incubator"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row v-if="incubatororpartner === 'Partner'">
          <b-col md="12">
            <b-form-group
              label="Select Partner"
              label-for="selectedPartner"
            >
              <v-select
                id="selectedPartner"
                v-model="selectedPartner"
                :get-option-label="option => option.full_name"
                :options="partners.filter(option => option.type === 'partner')"
                placeholder="Select Partner"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row v-if="incubatororpartner === 'Mentor'">
          <b-col md="12">
            <b-form-group
              label="Select Mentor"
              label-for="selectedPartner"
            >
              <v-select
                id="selectedPartner"
                v-model="selectedPartner"
                :get-option-label="option => option.full_name"
                :options="partners.filter(option => option.type === 'mentor')"
                placeholder="Select Mentor"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row v-if="selectedPartner || selectedIncubator">
          <b-col md="12">
            <b-form-group
              label="Select Date & Slot"
              label-for="selectedDateSlot"
            >
              <flat-pickr
                id="selectedDateSlot"
                v-model="choiceslotdate"
                :config="{ enableTime: false,dateFormat: 'Y-m-d'}"
                class="form-control"
                placeholder="Select Date"
              />
            </b-form-group>
            <div
              v-if="choicebookedslots.length > 0"
              class="d-flex"
              style="flex-wrap: wrap;"
            >
              <span
                v-for="(slots, i) in choicebookedslots"
                :key="i"
                class="bg-light-primary text-primary d-flex align-items-center mr-50 mb-50"
                style="border-radius: 200px; padding: 2px 8px;"
              >
                <b-form-radio
                  v-model="selectedSlot"
                  name="slot"
                  :value="slots"
                >
                  {{ `${new Date(slots.begindatetimestamp)
                    .toLocaleString('en-US', {
                      hour: 'numeric',
                      minute: 'numeric',
                      timeZone: gotUserData.timezone.abbr,
                      hour12: true,
                    })} - ${new Date(slots.enddatetimestamp)
                    .toLocaleString('en-US', {
                      hour: 'numeric',
                      minute: 'numeric',
                      timeZone: gotUserData.timezone.abbr,
                      hour12: true,
                    })}` }}
                </b-form-radio>
              </span>
            </div>
            <div
              v-else
            >
              <b-badge variant="primary">
                No Slots Available
              </b-badge>
            </div>
          </b-col>
        </b-row>
        <b-row
          v-if="selectedSlot"
          class="mt-1"
        >
          <b-col md="12">
            <b-form-group
              label="Title"
              label-for="title"
            >
              <b-form-input
                id="title"
                v-model="title"
                placeholder="Meeting title"
              />
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group
              label="Description"
              label-for="description"
            >
              <b-form-textarea
                id="description"
                v-model="description"
                placeholder="Enter Description"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </div>
    </b-modal>
    <b-modal
      id="addSlotModalold"
      ok-title="Save"
      no-close-on-backdrop
      size="lg"
      title="Add Slot"
      @ok="addSlot"
    >
      <b-row>
        <b-col md="12">
          <b-form-group
            class="mb-2"
            label="Select Date"
            label-for="slotDate"
          >
            <flat-pickr
              id="slotDate"
              v-model="slotdate"
              :config="{ enableTime: false,dateFormat: 'Y-m-d'}"
              class="form-control"
              placeholder="Select Date"
              @on-change="generateClock()"
            />
          </b-form-group>
        </b-col>

        <b-col
          v-if="slotdate"
          md="12"
        >
          <h6>Scheduled Slots</h6>
        </b-col>

        <b-col
          v-if="slotdate"
          md="12"
          class="d-flex mb-2"
          style="flex-wrap: wrap;"
        >
          <span
            v-for="(slots, i) in bookedslots"
            :key="i"
            style="border-radius: 200px; padding: 2px 8px;"
            :class="`${slots.is_available ? 'bg-success' : 'bg-danger'} text-white d-flex align-items-center mr-50 mb-50`"
          >
            {{ `${new Date(slots.begindatetimestamp)
              .toLocaleString('en-US', {
                hour: 'numeric',
                minute: 'numeric',
                timeZone: gotUserData.timezone.abbr,
                hour12: true,
              })} - ${new Date(slots.enddatetimestamp)
              .toLocaleString('en-US', {
                hour: 'numeric',
                minute: 'numeric',
                timeZone: gotUserData.timezone.abbr,
                hour12: true,
              })}` }}
            <feather-icon
              v-if="slots.is_available"
              icon="XCircleIcon"
              style="margin-left:4px; cursor: pointer;"
              @click="deleteSlot(slots.id, i)"
            />
          </span>
        </b-col>

        <b-col
          v-if="slotdate"
          md="12"
        >
          <b-row>
            <b-col md="11">
              <b-row>
                <b-col
                  md="6"
                >
                  <b-form-group
                    class="mb-2"
                    label="Start time"
                    label-for="StartDateAndTime"
                  >
                    <div
                      ref="bd"
                      class="timepicker-ui"
                    >
                      <b-form-input
                        v-model="slotbeginTime"
                        type="text"
                        class="timepicker-ui-input"
                        placeholder="Select start time"
                      />
                    </div>
                    <!-- <flat-pickr
                      id="StartDateAndTime"
                      v-model="slotbeginTime"
                      :config="{ enableTime: true, dateFormat: 'h:i K', noCalendar: true}"
                      class="form-control"
                      placeholder="Select start tIme"
                    /> -->
                  </b-form-group>
                </b-col>

                <b-col
                  md="6"
                >
                  <b-form-group
                    class="mb-2"
                    label="End time"
                    label-for="EndDateAndTime"
                  >
                    <div
                      ref="ed"
                      class="timepicker-ui"
                    >
                      <b-form-input
                        v-model="slotendTime"
                        type="text"
                        class="timepicker-ui-input"
                        placeholder="Select end time"
                      />
                    </div>
                    <!-- <flat-pickr
                      id="EndDateAndTIme"
                      v-model="slotendTime"
                      :config="{ enableTime: true,dateFormat: 'h:i K', minDate: slotbeginTime, noCalendar: true }"
                      class="form-control"
                      placeholder="Select end tIme"
                    /> -->
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>
            <b-col md="1">
              <span
                class="mt-2 d-flex align-items-center bg-primary text-white justify-content-center"
                style="padding-top: 0.786rem; padding-bottom: 0.786rem; border-radius: 0.358rem; cursor: pointer;"
                @click="addtoselectslot()"
              >
                <feather-icon icon="PlusIcon" />
              </span>
            </b-col>
          </b-row>
        </b-col>

        <b-col
          v-if="slotdate"
          md="12"
        >
          <h6>Selected Slots</h6>
        </b-col>

        <b-col
          v-if="slotdate"
          md="12"
          class="d-flex mb-2"
          style="flex-wrap: wrap;"
        >
          <span
            v-for="(slots, index) in selectedslots"
            :key="index"
            class="bg-warning text-white d-flex align-items-center mr-1"
            style="border-radius: 200px; padding: 2px 8px;"
          >
            {{ `${new Date(slots.begintime)
              .toLocaleString('en-US', {
                hour: 'numeric',
                minute: 'numeric',
                timeZone: gotUserData.timezone.abbr,
                hour12: true,
              })} - ${new Date(slots.endtime)
              .toLocaleString('en-US', {
                hour: 'numeric',
                minute: 'numeric',
                timeZone: gotUserData.timezone.abbr,
                hour12: true,
              })}` }}
            <feather-icon
              icon="XCircleIcon"
              style="margin-left:4px; cursor: pointer;"
              @click="removeselectedslot(index)"
            />
          </span>
        </b-col>
      </b-row>
    </b-modal>
    <b-modal
      id="scheduleSlotModal"
      ok-title="Save"
      no-close-on-backdrop
      size="lg"
      title="Schedule"
      @ok="addSchedule"
    >
      <b-row v-if="scheduleModalTab !== 0">
        <b-col>
          <b-button
            class="mb-1"
            variant="light"
            @click="scheduleModalTab = scheduleModalTab - 1"
          >
            <feather-icon
              icon="ChevronLeftIcon"
            />
            <span class="ml-1">Back</span>
          </b-button>
        </b-col>
      </b-row>
      <div v-if="scheduleModalTab === 0">
        <b-row>
          <b-col md="12">
            <vue-good-table
              v-if="selectedRowIndex !== null"
              :columns="scheduleColumns"
              :pagination-options="{
                enabled: true,
                perPage:pageLength
              }"
              :rows="rows[selectedRowIndex].sessions_participanttables[0].sessions_slots_tables"
              :search-options="{
                enabled: true,
                externalQuery: searchTerm }"
            >
              <template
                slot="table-row"
                slot-scope="props"
              >
                <span v-if="props.column.label === 'Time'">
                  <span v-if="props.formattedRow[props.column.field] !== '-'">
                    {{ props.formattedRow[props.column.field] }}
                  </span>
                </span>
                <span v-else-if="props.column.field === 'availablemembers'">
                  <span
                    v-if="props.row.is_accepted !== null"
                    class="text-primary d-flex justify-content-between align-items-center"
                  >
                    <span
                      v-if="props.row.is_accepted"
                    >
                      <b-badge
                        variant="light-primary"
                        class="mr-1"
                      >
                        Marked Available
                      </b-badge>
                      <b-button
                        size="sm"
                        variant="outline-danger"
                        @click="updateSlotRequest(props.row.id, false)"
                      >
                        Unavailable
                      </b-button>
                    </span>
                    <span
                      v-else
                    >
                      <b-badge
                        variant="light-danger"
                        class="mr-1"
                      >
                        Marked Unavailable
                      </b-badge>
                      <b-button
                        size="sm"
                        variant="outline-primary"
                        @click="updateSlotRequest(props.row.id, true)"
                      >
                        Available
                      </b-button>
                    </span>
                  </span>
                  <span v-else>
                    <b-button
                      size="sm"
                      variant="outline-primary"
                      class="mr-1"
                      @click="updateSlotRequest(props.row.id, true)"
                    >
                      Available
                    </b-button>
                    <b-button
                      size="sm"
                      variant="outline-danger"
                      @click="updateSlotRequest(props.row.id, false)"
                    >
                      Unavailable
                    </b-button>
                  </span>
                </span>

                <!-- Column: Common -->
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>
              <!-- pagination -->
              <template
                slot="pagination-bottom"
                slot-scope="props"
              >
                <div class="d-flex justify-content-between flex-wrap">
                  <div class="d-flex align-items-center mb-0 mt-1">
                    <span class="text-nowrap ">
                      Showing 1 to
                    </span>
                    <b-form-select
                      v-model="pageLength"
                      :options="['3','5','10']"
                      class="mx-1"
                      @input="(value)=>props.perPageChanged({currentPerPage:value})"
                    />
                    <span class="text-nowrap"> of {{ props.total }} entries </span>
                  </div>
                  <div>
                    <b-pagination
                      :per-page="pageLength"
                      :total-rows="props.total"
                      :value="1"
                      class="mt-1 mb-0"
                      first-number
                      last-number
                      next-class="next-item"
                      prev-class="prev-item"
                      @input="(value)=>props.pageChanged({currentPage:value})"
                    >
                      <template #prev-text>
                        <feather-icon
                          icon="ChevronLeftIcon"
                          size="18"
                        />
                      </template>
                      <template #next-text>
                        <feather-icon
                          icon="ChevronRightIcon"
                          size="18"
                        />
                      </template>
                    </b-pagination>
                  </div>
                </div>
              </template>
            </vue-good-table>
          </b-col>
          <b-col
            cols="12"
            :class="`${scheduleChoice !== 'direct' ? 'd-none' : ''}`"
          >
            <h6 class="my-1">
              Direct Schedule
            </h6>
            <b-row>
              <b-col md="4">
                <b-form-group
                  class="mb-2"
                  label="Date"
                  label-for="StartDate"
                >
                  <flat-pickr
                    id="StartDate"
                    v-model="schedulebeginDate"
                    :config="{ enableTime: true, dateFormat: 'Y-m-d'}"
                    class="form-control"
                    placeholder="Select start date"
                  />
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                  class="mb-2"
                  label="Start Time"
                  label-for="StartTime"
                >
                  <div
                    ref="sbt"
                    class="timepicker-ui"
                  >
                    <b-form-input
                      v-model="schedulebeginTime"
                      type="text"
                      class="timepicker-ui-input"
                      placeholder="Select end time"
                    />
                  </div>
                </b-form-group>
              </b-col>
              <!-- end date and time -->
              <b-col md="4">
                <b-form-group
                  class="mb-2"
                  label="End Time"
                  label-for="EndTime"
                >
                  <div
                    ref="set"
                    class="timepicker-ui"
                  >
                    <b-form-input
                      v-model="scheduleendTime"
                      type="text"
                      class="timepicker-ui-input"
                      placeholder="Select end time"
                    />
                  </div>
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
          <b-col
            cols="12"
            :class="`${scheduleChoice !== 'addslot' ? 'd-none' : ''}`"
          >
            <h6 class="my-1">
              Add Slots
            </h6>
            <b-row>
              <b-col md="4">
                <b-form-group
                  class="mb-2"
                  label="Date"
                  label-for="StartDate"
                >
                  <flat-pickr
                    id="StartDate"
                    v-model="schedulebeginDateR"
                    :config="{ enableTime: true, dateFormat: 'Y-m-d'}"
                    class="form-control"
                    placeholder="Select start date"
                  />
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group
                  class="mb-2"
                  label="Start Time"
                  label-for="StartTime"
                >
                  <div
                    ref="sbtr"
                    class="timepicker-ui"
                  >
                    <b-form-input
                      v-model="schedulebeginTimeR"
                      type="text"
                      class="timepicker-ui-input"
                      placeholder="Select end time"
                    />
                  </div>
                </b-form-group>
              </b-col>
              <!-- end date and time -->
              <b-col md="3">
                <b-form-group
                  class="mb-2"
                  label="End Time"
                  label-for="EndTime"
                >
                  <div
                    ref="setr"
                    class="timepicker-ui"
                  >
                    <b-form-input
                      v-model="scheduleendTimeR"
                      type="text"
                      class="timepicker-ui-input"
                      placeholder="Select end time"
                    />
                  </div>
                </b-form-group>
              </b-col>
              <b-col md="2">
                <b-button
                  variant="primary"
                  block
                  class="mt-2"
                  @click="addScheduleRequestingSlot()"
                >
                  <feather-icon
                    icon="PlusIcon"
                    class="mr-1"
                  />
                  <span>Add</span>
                </b-button>
              </b-col>
            </b-row>
            <h6 v-if="scheduleslotRequests.length > 0">
              Alternative Dates
            </h6>
            <b-row>
              <b-col cols="12">
                <b-badge
                  v-for="(slot, i) in scheduleslotRequests"
                  :key="i"
                  variant="primary"
                  class="mb-50 mr-50"
                >
                  {{ `${new Date(slot.begin)
                    .toLocaleString('en-US', {
                      month: 'short',
                      day: 'numeric',
                      hour: 'numeric',
                      minute: 'numeric',
                      timeZone: gotUserData.timezone.abbr,
                      hour12: true,
                    })} - ${new Date(slot.end)
                    .toLocaleString('en-US', {
                      hour: 'numeric',
                      minute: 'numeric',
                      timeZone: gotUserData.timezone.abbr,
                      hour12: true,
                    })}` }}
                  <feather-icon
                    style="cursor: pointer;"
                    icon="XIcon"
                    class="ml-1"
                    @click="removeScheduleRequestingSlot(i)"
                  />
                </b-badge>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </div>
      <div v-if="scheduleModalTab === 1">
        <b-row>
          <b-col md="12">
            <vue-good-table
              :columns="memberColumns"
              :pagination-options="{
                enabled: true,
                perPage:pageLength
              }"
              :rows="availablemembers"
              :search-options="{
                enabled: true,
                externalQuery: searchTerm }"
            >
              <template
                slot="table-row"
                slot-scope="props"
              >
                <span v-if="props.column.label === 'Type'">
                  <b-badge variant="light-primary">
                    {{ props.formattedRow[props.column.field] }}
                  </b-badge>
                </span>
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>
              <!-- pagination -->
              <template
                slot="pagination-bottom"
                slot-scope="props"
              >
                <div class="d-flex justify-content-between flex-wrap">
                  <div class="d-flex align-items-center mb-0 mt-1">
                    <span class="text-nowrap ">
                      Showing 1 to
                    </span>
                    <b-form-select
                      v-model="pageLength"
                      :options="['3','5','10']"
                      class="mx-1"
                      @input="(value)=>props.perPageChanged({currentPerPage:value})"
                    />
                    <span class="text-nowrap"> of {{ props.total }} entries </span>
                  </div>
                  <div>
                    <b-pagination
                      :per-page="pageLength"
                      :total-rows="props.total"
                      :value="1"
                      class="mt-1 mb-0"
                      first-number
                      last-number
                      next-class="next-item"
                      prev-class="prev-item"
                      @input="(value)=>props.pageChanged({currentPage:value})"
                    >
                      <template #prev-text>
                        <feather-icon
                          icon="ChevronLeftIcon"
                          size="18"
                        />
                      </template>
                      <template #next-text>
                        <feather-icon
                          icon="ChevronRightIcon"
                          size="18"
                        />
                      </template>
                    </b-pagination>
                  </div>
                </div>
              </template>
            </vue-good-table>
          </b-col>
        </b-row>
      </div>
    </b-modal>
    <b-modal
      v-if="rows.length"
      id="updateLinks"
      ok-title="Save"
      no-close-on-backdrop
      title="Update Meeting Links"
      @ok="updateLinks"
    >
      <b-row>
        <b-col cols="12">
          <b-form-group
            label="Meeting Link"
            label-for="updateMeetingLink"
          >
            <b-form-input
              id="updateMeetingLink"
              v-model="rows[selectedRow].meeting_link"
              placeholder="Meeting Link"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            label="Recording Link"
            label-for="updateRecordingLink"
          >
            <b-form-input
              id="updateRecordingLink"
              v-model="rows[selectedRow].recording_link"
              placeholder="Recording Link"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>
<script>

import {
  BBadge, BButton, BFormGroup, BFormInput, BFormSelect, BPagination, BModal, BFormTextarea, BFormRadio,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import gql from 'graphql-tag'
import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'
import Treeselect from '@riophae/vue-treeselect'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import { TimepickerUI } from 'timepicker-ui'
import { getUserData } from '@/utils/auth'
// import { Promised } from 'vue-promised'

export default {
  components: {
    BBadge,
    BFormInput,
    BFormGroup,
    BButton,
    VueGoodTable,
    BPagination,
    BFormSelect,
    BModal,
    vSelect,
    Treeselect,
    flatPickr,
    BFormTextarea,
    BFormRadio,
    // Promised,
  },
  data() {
    return {
      program: null,
      title: '',
      type: '',
      slotdate: '',
      selectedRowId: null,
      selectedRowIndex: null,
      slotbegindatetimestamp: null,
      slotenddatetimestamp: null,
      bookedbegindatetimestamp: null,
      bookedenddatetimestamp: null,
      choiceslotdate: '',
      slotbeginTime: null,
      slotendTime: null,
      bookedslots: [],
      choicebookedslots: [],
      selectedslots: [],
      availableslots: [],
      availabilitycount: 0,
      availablemembers: [],
      globalavailablemembers: [],
      slotRequests: [],
      scheduleslotRequests: [],
      scheduleParticipantIds: [],
      beginDate: null,
      beginTime: null,
      endTime: null,
      beginDateR: null,
      beginTimeR: null,
      endTimeR: null,
      schedulebeginDate: null,
      schedulebeginTime: null,
      scheduleendTime: null,
      schedulebeginDateR: null,
      schedulebeginTimeR: null,
      scheduleendTimeR: null,
      endDate: null,
      meetingLink: '',
      description: '',
      incubatororpartner: null,
      operationsId: null,
      modalTab: 1,
      scheduleModalTab: 0,
      choice: 'slot',
      scheduleChoice: '',
      status: '',
      mutationLoading: false,
      participants: [],
      requestSlot: true,
      gotUserData: getUserData(),
      selectedIncubator: null,
      selectedPartner: null,
      selectedSlot: null,
      meetingTypeOptions: [
        'webinar', 'one-on-one', 'multi participant',
      ],
      searchTerm: '',
      selectedRow: 0,
      pageLength: 5,
      filter: {
        status: 'Current',
      },
      columns: [
        {
          label: 'Meeting',
          field(row) {
            return `${row.title} - ${row.type}`
          },
          tdClass: 'text-capitalize',
        },
        // {
        //   label: 'Operation',
        //   field(row) {
        //     return row.programs_operationstable.title
        //   },
        //   filterOptions: {
        //     enabled: true, // enable filter for this column
        //     customFilter: true, // use custom filter component
        //   },
        // },
        {
          label: 'Time',
          field(row) {
            if (!row.begin_time) return '-'
            return `${new Date(row.begin_time)
              .toLocaleString('en-US', {
                month: 'short',
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
                timeZone: getUserData().timezone.abbr,
                hour12: true,
              })} - ${new Date(row.end_time)
              .toLocaleString('en-US', {
                hour: 'numeric',
                minute: 'numeric',
                timeZone: getUserData().timezone.abbr,
                hour12: true,
              })}`
          },
        },
        {
          label: 'Status',
          field: 'status',
        },
        {
          label: 'Links',
          field: 'buttons',
          width: '11rem',
          sortable: false,
        },
        {
          label: 'Action',
          field: 'action',
          sortable: false,
        },
      ],
      scheduleColumns: [
        {
          label: 'Slot',
          field(row) {
            if (!row.begindatetimestamp) return '-'
            return `${new Date(row.begindatetimestamp)
              .toLocaleString('en-US', {
                month: 'short',
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
                timeZone: getUserData().timezone.abbr,
                hour12: true,
              })} - ${new Date(row.enddatetimestamp)
              .toLocaleString('en-US', {
                hour: 'numeric',
                minute: 'numeric',
                timeZone: getUserData().timezone.abbr,
                hour12: true,
              })}`
          },
        },
        {
          label: 'Action',
          field: 'availablemembers',
        },
      ],
      memberColumns: [
        {
          label: 'Name',
          field(row) {
            if (row.programs_startupparticipant.programs_applicantstable) {
              if (row.programs_startupparticipant.programs_applicantstable.users_organizationtable) {
                return row.programs_startupparticipant.programs_applicantstable.users_organizationtable.title
              }
              return row.programs_startupparticipant.programs_applicantstable.users_customuser.full_name
            }
            if (row.programs_partnerstable.users_organizationtable) {
              return row.programs_partnerstable.users_organizationtable.title
            }
            return row.programs_partnerstable.users_customuser.full_name
          },
        },
        {
          label: 'Type',
          field(row) {
            if (row.programs_startupparticipant.programs_applicantstable) {
              if (row.programs_startupparticipant.programs_applicantstable.users_organizationtable) {
                return 'Startup'
              }
              return 'Student'
            }
            if (row.programs_partnerstable.users_organizationtable) {
              return 'Partner'
            }
            return 'Mentor'
          },
        },
      ],
      rows: [],
      selectedOperation: null,
      selectedExercise: null,
      selectedMilestone: null,
      partners: [],
      incubators: [],
      exercises: [],
      milestones: [],
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Scheduled: 'light-primary',
        /* eslint-enable key-spacing */
      }
      return status => statusColor[status]
    },
  },
  methods: {
    generateClock() {
      setTimeout(() => {
        const bd = new TimepickerUI(this.$refs.bd, {
          editable: true,
          preventDefault: false,
          color: '#7367F0',
        })
        bd.create()
        this.$refs.bd.addEventListener('accept', ({ detail }) => {
          if (this.checkConflicts(this.generateUTCDateTime(this.slotdate, detail), 'start')) {
            this.slotbeginTime = this.generateUTCDateTime(this.slotdate, detail)
          } else {
            this.slotbeginTime = ''
          }
        })
        const ed = new TimepickerUI(this.$refs.ed, {
          editable: true,
          preventDefault: false,
          color: '#7367F0',
        })
        ed.create()
        this.$refs.ed.addEventListener('accept', ({ detail }) => {
          if (this.checkConflicts(this.generateUTCDateTime(this.slotdate, detail), 'end')) {
            this.slotendTime = this.generateUTCDateTime(this.slotdate, detail)
          } else {
            this.slotendTime = ''
          }
        })
      }, 1000)
    },
    generateDirectClock() {
      setTimeout(() => {
        const bt = new TimepickerUI(this.$refs.bt, {
          editable: true,
          preventDefault: false,
          color: '#7367F0',
        })
        bt.create()
        this.$refs.bt.addEventListener('accept', ({ detail }) => {
          if (this.checkTimeConflict(this.generateUTCDateTime(this.beginDate, detail), 'start')) {
            this.beginTime = this.generateUTCDateTime(this.beginDate, detail)
          } else {
            this.beginTime = ''
          }
        })
        const et = new TimepickerUI(this.$refs.et, {
          editable: true,
          preventDefault: false,
          color: '#7367F0',
        })
        et.create()
        this.$refs.et.addEventListener('accept', ({ detail }) => {
          if (this.checkTimeConflict(this.generateUTCDateTime(this.beginDate, detail), 'end')) {
            this.endTime = this.generateUTCDateTime(this.beginDate, detail)
          } else {
            this.endTime = ''
          }
        })
        const btr = new TimepickerUI(this.$refs.btr, {
          editable: true,
          preventDefault: false,
          color: '#7367F0',
        })
        btr.create()
        this.$refs.btr.addEventListener('accept', ({ detail }) => {
          if (this.checkTimeConflict(this.generateUTCDateTime(this.beginDateR, detail), 'start')) {
            this.beginTimeR = this.generateUTCDateTime(this.beginDateR, detail)
          } else {
            this.beginTimeR = ''
          }
        })
        const etr = new TimepickerUI(this.$refs.etr, {
          editable: true,
          preventDefault: false,
          color: '#7367F0',
        })
        etr.create()
        this.$refs.etr.addEventListener('accept', ({ detail }) => {
          if (this.checkTimeConflict(this.generateUTCDateTime(this.beginDateR, detail), 'end')) {
            this.endTimeR = this.generateUTCDateTime(this.beginDateR, detail)
          } else {
            this.endTimeR = ''
          }
        })
      }, 1000)
    },
    generateScheduleClock() {
      setTimeout(() => {
        const sbt = new TimepickerUI(this.$refs.sbt, {
          editable: true,
          preventDefault: false,
          color: '#7367F0',
        })
        sbt.create()
        this.$refs.sbt.addEventListener('accept', ({ detail }) => {
          if (this.checkTimeConflict(this.generateUTCDateTime(this.schedulebeginDate, detail), 'start')) {
            this.schedulebeginTime = this.generateUTCDateTime(this.schedulebeginDate, detail)
          } else {
            this.schedulebeginTime = ''
          }
        })
        const set = new TimepickerUI(this.$refs.set, {
          editable: true,
          preventDefault: false,
          color: '#7367F0',
        })
        set.create()
        this.$refs.set.addEventListener('accept', ({ detail }) => {
          if (this.checkTimeConflict(this.generateUTCDateTime(this.schedulebeginDate, detail), 'end')) {
            this.scheduleendTime = this.generateUTCDateTime(this.schedulebeginDate, detail)
          } else {
            this.scheduleendTime = ''
          }
        })
        const sbtr = new TimepickerUI(this.$refs.sbtr, {
          editable: true,
          preventDefault: false,
          color: '#7367F0',
        })
        sbtr.create()
        this.$refs.sbtr.addEventListener('accept', ({ detail }) => {
          if (this.checkTimeConflict(this.generateUTCDateTime(this.schedulebeginDateR, detail), 'start')) {
            this.schedulebeginTimeR = this.generateUTCDateTime(this.schedulebeginDateR, detail)
          } else {
            this.schedulebeginTimeR = ''
          }
        })
        const setr = new TimepickerUI(this.$refs.setr, {
          editable: true,
          preventDefault: false,
          color: '#7367F0',
        })
        setr.create()
        this.$refs.setr.addEventListener('accept', ({ detail }) => {
          if (this.checkTimeConflict(this.generateUTCDateTime(this.schedulebeginDateR, detail), 'end')) {
            this.scheduleendTimeR = this.generateUTCDateTime(this.schedulebeginDateR, detail)
          } else {
            this.scheduleendTimeR = ''
          }
        })
      }, 1000)
    },
    addScheduleRequestingSlot() {
      const temp = this.scheduleslotRequests
      temp.push({
        date: this.schedulebeginDateR,
        begin: this.schedulebeginTimeR,
        end: this.scheduleendTimeR,
      })
      this.scheduleslotRequests = temp
    },
    removeScheduleRequestingSlot(index) {
      const temp = this.scheduleslotRequests
      temp.splice(index, 1)
      this.scheduleslotRequests = temp
    },
    addRequestingSlot() {
      const temp = this.slotRequests
      temp.push({
        date: this.beginDateR,
        begin: this.beginTimeR,
        end: this.endTimeR,
      })
      this.slotRequests = temp
    },
    removeRequestingSlot(index) {
      const temp = this.slotRequests
      temp.splice(index, 1)
      this.slotRequests = temp
    },
    generateUTCDateTime(date, time) {
      return (new Date(new Date((new Date(date)).getFullYear(), (new Date(date)).getMonth(), (new Date(date)).getDate(), this.getHourIn24(time.hour, time.type), time.minutes).toLocaleString('en-US', { timeZone: getUserData().timezone.abbr })).toISOString())
    },
    getHourIn24(hour, type) {
      if (hour === 12 && type === 'AM') {
        return '00'
      }
      if (type === 'PM' && hour < 12) {
        return parseInt(hour, 10) + 12
      }
      return hour
    },
    checkConflicts(check, type) {
      let found = 0
      if (type === 'end' && (new Date(check)).getTime() <= (new Date(this.begintime)).getTime()) {
        found = 1
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'There must be a 15 Minutes Slot',
            icon: 'XIcon',
            variant: 'warning',
          },
        })
      }
      this.selectedslots.map(slot => {
        if ((new Date(check)).getTime() > (new Date(slot.begintime)).getTime() && (new Date(check)).getTime() < (new Date(slot.endtime)).getTime()) {
          found = 1
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Conflicting Slot Time',
              icon: 'XIcon',
              variant: 'warning',
            },
          })
        }
        if (
          type === 'start' && (
            (
              ((new Date(check)).getTime() + (60000 * 25)) > (new Date(slot.begintime)).getTime() && (new Date(check)).getTime() < (new Date(slot.endtime)).getTime()
            ) || (
              ((new Date(check)).getTime() - (60000 * 10)) < (new Date(slot.endtime)).getTime() && (new Date(check)).getTime() > (new Date(slot.begintime)).getTime()
            )
          )
        ) {
          found = 1
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Conflicting Default Break Time',
              icon: 'XIcon',
              variant: 'warning',
            },
          })
        }
        if (
          type === 'end' && (
            (
              ((new Date(check)).getTime() + (60000 * 10)) > (new Date(slot.begintime)).getTime() && (new Date(check)).getTime() < (new Date(slot.endtime)).getTime()
            ) || (
              ((new Date(check)).getTime() - (60000 * 25)) < (new Date(slot.endtime)).getTime() && (new Date(check)).getTime() > (new Date(slot.begintime)).getTime()
            )
          )
        ) {
          found = 1
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Conflicting Default Break Time',
              icon: 'XIcon',
              variant: 'warning',
            },
          })
        }
        return 0
      })
      this.bookedslots.map(slot => {
        if ((new Date(check)).getTime() > (new Date(slot.begindatetimestamp)).getTime() && (new Date(check)).getTime() < (new Date(slot.begindatetimestamp)).getTime()) {
          found = 1
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Conflicting Slot Time',
              icon: 'XIcon',
              variant: 'warning',
            },
          })
        }
        if (
          type === 'start' && (
            (
              ((new Date(check)).getTime() + (60000 * 25)) > (new Date(slot.begindatetimestamp)).getTime() && (new Date(check)).getTime() < (new Date(slot.begindatetimestamp)).getTime()
            ) || (
              ((new Date(check)).getTime() - (60000 * 10)) < (new Date(slot.begindatetimestamp)).getTime() && (new Date(check)).getTime() > (new Date(slot.begindatetimestamp)).getTime()
            )
          )
        ) {
          found = 1
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Conflicting Default Break Time',
              icon: 'XIcon',
              variant: 'warning',
            },
          })
        }
        if (
          type === 'end' && (
            (
              ((new Date(check)).getTime() + (60000 * 10)) > (new Date(slot.begindatetimestamp)).getTime() && (new Date(check)).getTime() < (new Date(slot.begindatetimestamp)).getTime()
            ) || (
              ((new Date(check)).getTime() - (60000 * 25)) < (new Date(slot.begindatetimestamp)).getTime() && (new Date(check)).getTime() > (new Date(slot.begindatetimestamp)).getTime()
            )
          )
        ) {
          found = 1
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Conflicting Default Break Time',
              icon: 'XIcon',
              variant: 'warning',
            },
          })
        }
        return 0
      })
      if (found) {
        return false
      }
      return true
    },
    checkTimeConflict(check, type) {
      let found = 0
      if (type === 'end' && ((new Date(check)).getTime() <= (new Date(this.beginTime)).getTime() || (new Date(check)).getTime() - (60000 * 15) <= (new Date(this.beginTime)).getTime())) {
        found = 1
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'End time can not be less than or equal to begin time and must be a minimum 15 minute slot',
            icon: 'XIcon',
            variant: 'warning',
          },
        })
      }
      if (found) {
        return false
      }
      return true
    },
    addtoselectslot() {
      if (this.slotbeginTime) {
        if (this.slotendTime) {
          this.selectedslots.push({
            begintime: this.slotbeginTime,
            endtime: this.slotendTime,
          })
          this.slotbeginTime = ''
          this.slotendTime = ''
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Select End Time',
              icon: 'XIcon',
              variant: 'warning',
            },
          })
        }
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Select Begin Time',
            icon: 'XIcon',
            variant: 'warning',
          },
        })
      }
    },
    removeselectedslot(index) {
      this.selectedslots.splice(index, 1)
    },
    toggleChoice(value) {
      if (this.scheduleChoice === value) {
        this.scheduleChoice = ''
      } else {
        this.scheduleChoice = value
      }
    },
    bookSlot(rowData) {
      // console.log(rowData)
      this.$apollo.mutate({
        mutation: gql`
        mutation updateSession {
          update_sessions_scheduletable_by_pk(pk_columns: {id: ${this.selectedRowId}}, _set: {
            begin_time: "${rowData.begindatetimestamp}",
            end_time: "${rowData.enddatetimestamp}",
          }) {
            id
          }
        }`,
        update: (store, { data: { update_sessions_scheduletable_by_pk } }) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: update_sessions_scheduletable_by_pk.id ? 'Booked successfully' : 'Failed to book',
              icon: update_sessions_scheduletable_by_pk.id ? 'CheckIcon' : 'XIcon',
              variant: update_sessions_scheduletable_by_pk.id ? 'success' : 'warning',
            },
          })
          this.$apollo.queries.rows.refetch()
          this.mutationLoading = false
        },
      })
    },
    getAllScheduleParticipants() {
      const temp = []
      this.scheduleParticipantIds.map(participant => {
        this.scheduleslotRequests.map(slot => {
          temp.push({
            begindatetimestamp: slot.begin,
            enddatetimestamp: slot.end,
            is_visible: false,
            is_available: false,
            is_slotalternative: true,
            sessions_participant_id: participant.id,
          })
          return 0
        })
        return 0
      })
      return temp
    },
    addSchedule() {
      if (this.scheduleChoice === 'direct') {
        this.$apollo.mutate({
          mutation: gql`
          mutation updateSession {
            update_sessions_scheduletable_by_pk(pk_columns: {id: ${this.selectedRowId}}, _set: {
              begin_time: "${this.schedulebeginTime}",
              end_time: "${this.scheduleendTime}",
            }) {
              id
            }
          }`,
          update: (store, { data: { update_sessions_scheduletable_by_pk } }) => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: update_sessions_scheduletable_by_pk.id ? 'Added successfully' : 'Failed to add',
                icon: update_sessions_scheduletable_by_pk.id ? 'CheckIcon' : 'XIcon',
                variant: update_sessions_scheduletable_by_pk.id ? 'success' : 'warning',
              },
            })
            this.$apollo.queries.rows.refetch()
            this.mutationLoading = false
          },
        })
      } else if (this.scheduleChoice === 'addslot') {
        this.$apollo.mutate({
          mutation: gql`
          mutation insertSessions($objects: [sessions_slots_table_insert_input!]!) {
            insert_sessions_slots_table (objects: $objects) {
              affected_rows
            }
          }`,
          variables: {
            objects: this.getAllScheduleParticipants(),
          },
          update: (store, { data: { insert_sessions_slots_table } }) => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: insert_sessions_slots_table.affected_rows ? 'Added successfully' : 'Failed to add',
                icon: insert_sessions_slots_table.affected_rows ? 'CheckIcon' : 'XIcon',
                variant: insert_sessions_slots_table.affected_rows ? 'success' : 'warning',
              },
            })
            this.$apollo.queries.rows.refetch()
            this.mutationLoading = false
          },
        })
      }
    },
    addSlot() {
      this.mutationLoading = true
      this.$apollo.mutate({
        mutation: gql`
        mutation insertSlots($object: [sessions_slots_table_insert_input!]!) {
          insert_sessions_slots_table (objects: $object) {
            affected_rows
          }
        }`,
        variables: {
          object: this.selectedslots.map(slot => ({
            begindatetimestamp: slot.begintime,
            enddatetimestamp: slot.endtime,
            startup_organization_id: getUserData().associatedOrgDetails.length > 0 ? getUserData().associatedOrgDetails[0].organization_id : null,
            student_user_id: getUserData().associatedOrgDetails.length > 0 ? null : getUserData().id,
            incubator_id: this.program.organization_id_id,
          })),
        },
        update: (store, { data: { insert_sessions_slots_table } }) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: insert_sessions_slots_table.affected_rows ? 'Added successfully' : 'Failed to add',
              icon: insert_sessions_slots_table.affected_rows ? 'CheckIcon' : 'XIcon',
              variant: insert_sessions_slots_table.affected_rows ? 'success' : 'warning',
            },
          })
          this.$apollo.queries.bookedslots.refetch()
          this.mutationLoading = false
        },
      })
    },
    deleteSlot(id, key) {
      this.$bvModal.msgBoxConfirm(
        `Are you sure you want to delete slot "${new Date(this.bookedslots[key].begindatetimestamp)
          .toLocaleString('en-US', {
            hour: 'numeric',
            minute: 'numeric',
            timeZone: getUserData().timezone.abbr,
            hour12: true,
          })} - ${new Date(this.bookedslots[key].enddatetimestamp)
          .toLocaleString('en-US', {
            hour: 'numeric',
            minute: 'numeric',
            timeZone: getUserData().timezone.abbr,
            hour12: true,
          })}" ?`,
        {
          okTitle: 'No, Thanks',
          cancelTitle: 'Yes, Delete',
          title: 'Delete Slot',
        },
      )
        .then(value => {
          if (value === false) {
            this.mutationLoading = true
            this.$apollo.mutate(
              {
                mutation: gql`mutation deleteSlot {
                      update_sessions_slots_table_by_pk(pk_columns: {id: ${id}}, _set: {is_deleted: true}) {
                        id
                      }
                    }`,
                update: () => {
                  this.mutationLoading = false
                  this.$apollo.queries.bookedslots.refetch()
                },
              },
            )
          }
        })
    },
    normalizer(node) {
      return {
        id: node.id,
        label: node.title,
        children: node.programs_operationstables,
      }
    },
    milestonesnormalizer(node) {
      return {
        id: node.id,
        label: node.title,
        children: node.programs_progressstagetable,
      }
    },
    exercisesnormalizer(node) {
      return {
        id: node.id,
        label: node.title,
        children: node.programs_assignmenttable,
      }
    },
    filterNormalizer(node) {
      return {
        id: node.title,
        label: node.title,
        children: node.programs_operationstables,
      }
    },
    toTimestamp(strDate) {
      const datum = Date.parse(strDate)
      return datum / 1000
    },
    setAvailability(data) {
      this.selectedRowIndex = data.vgt_id
      this.selectedRowId = data.id
      this.availableslots = data.sessions_participanttables[0].sessions_slots_tables
      this.availabilitycount = data.count_all_participanttables.aggregate.count
    },
    updateSlotRequest(id, status) {
      this.$apollo.mutate({
        mutation: gql`
        mutation {
          update_sessions_slots_table_by_pk(pk_columns: {id: ${id} }, _set: {is_accepted: ${status}}) {
            id
          }
        }`,
        update: (store, { data: { update_sessions_slots_table_by_pk } }) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: update_sessions_slots_table_by_pk.id ? 'Updated successfully' : 'Failed to update',
              icon: update_sessions_slots_table_by_pk.id ? 'CheckIcon' : 'XIcon',
              variant: update_sessions_slots_table_by_pk.id ? 'success' : 'warning',
            },
          })
          this.$apollo.queries.rows.refetch()
          this.mutationLoading = false
        },
      })
    },
    getAllParticipants() {
      const temp = []
      if (this.selectedIncubator) {
        this.selectedIncubator.map(incubator => {
          let obj = {
            incubator_user_id: incubator,
            incubator_id: this.program.organization_id_id,
          }
          if (this.requestSlot) {
            obj = {
              ...obj,
              sessions_slots_tables: {
                data: this.slotRequests.map(slot => ({
                  begindatetimestamp: slot.begin,
                  enddatetimestamp: slot.end,
                  is_visible: false,
                  is_available: false,
                  is_slotalternative: true,
                })),
              },
            }
          }
          temp.push(obj)
          return 0
        })
      }
      if (this.selectedPartner) {
        this.selectedPartner.map(partner => {
          let obj = {
            program_partner_id: partner,
            incubator_id: this.program.organization_id_id,
          }
          if (this.requestSlot) {
            obj = {
              ...obj,
              sessions_slots_tables: {
                data: this.slotRequests.map(slot => ({
                  begindatetimestamp: slot.begin,
                  enddatetimestamp: slot.end,
                  is_visible: false,
                  is_available: false,
                  is_slotalternative: true,
                })),
              },
            }
          }
          temp.push(obj)
          return 0
        })
      }
      return temp
    },
    addMeeting() {
      this.mutationLoading = true
      if (this.choice === 'direct') {
        this.$apollo.mutate({
          mutation: gql`
          mutation insertSessions($data: [sessions_participanttable_insert_input!]!) {
            insert_sessions_scheduletable_one(object: {
              description: "${this.description}",
              program_participant_id: "${this.$route.params.aid}",
              program_id: ${this.$route.params.id},
              title: "${this.title}",
              sessions_participanttables: {data: $data},
              ${this.selectedOperation ? `
                operations_id: ${this.selectedOperation},
              ` : ''}
              ${this.selectedExercise ? `
                milestone_id: ${this.selectedExercise},
              ` : ''}
              ${this.selectedMilestone ? `
                exercise_id: ${this.selectedMilestone},
              ` : ''}
              ${this.requestSlot ? `
                is_request: "true",
              ` : `
                begin_time: "${this.beginTime}",
                end_time: "${this.endTime}",
              `}
            }) {
              id
            }
          }`,
          variables: {
            data: this.getAllParticipants(),
          },
          update: (store, { data: { insert_sessions_scheduletable_one } }) => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: insert_sessions_scheduletable_one.id ? 'Added successfully' : 'Failed to add',
                icon: insert_sessions_scheduletable_one.id ? 'CheckIcon' : 'XIcon',
                variant: insert_sessions_scheduletable_one.id ? 'success' : 'warning',
              },
            })
            this.$apollo.queries.rows.refetch()
            this.mutationLoading = false
          },
        })
      } else if (this.choice === 'slot') {
        this.$apollo.mutate({
          mutation: gql`
          mutation insertSessions($data: [sessions_participanttable_insert_input!]!) {
            insert_sessions_scheduletable_one(object: {
              description: "${this.description}",
              begin_time: "${this.selectedSlot.begindatetimestamp}",
              end_time: "${this.selectedSlot.enddatetimestamp}",
              program_id: ${this.$route.params.id},
              title: "${this.title}",
              sessions_participanttables: {data: $data},
            }) {
              id
            }
          }`,
          variables: {
            data: [
              {
                program_participant_id: this.$route.params.aid,
              },
              {
                program_partner_id: this.incubatororpartner === 'Partner' || this.incubatororpartner === 'Mentor' ? this.selectedPartner.partner_id : null,
                incubator_id: this.startuporincubator === 'Incubator' ? this.program.organization_id_id : null,
                incubator_user_id: this.startuporincubator === 'Incubator' ? this.selectedIncubator.id : null,
              },
            ],
          },
          update: () => {
            this.$apollo.mutate({
              mutation: gql`
              mutation updateSlot {
                update_sessions_slots_table_by_pk(pk_columns: {id: ${this.selectedSlot.id}}, _set: {is_available: false}) {
                  id
                }
              }`,
              update: (store, { data: { update_sessions_slots_table_by_pk } }) => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: update_sessions_slots_table_by_pk.id ? 'Added successfully' : 'Failed to add',
                    icon: update_sessions_slots_table_by_pk.id ? 'CheckIcon' : 'XIcon',
                    variant: update_sessions_slots_table_by_pk.id ? 'success' : 'warning',
                  },
                })
                this.$apollo.queries.rows.refetch()
                this.mutationLoading = false
              },
            })
          },
        })
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Please Choose Schedule Type',
            icon: 'XIcon',
            variant: 'warning',
          },
        })
      }
    },
    updateLinks() {
      this.mutationLoading = true
      this.$apollo.mutate({
        mutation: gql`
        mutation {
          update_sessions_scheduletable_by_pk(pk_columns: {id: ${this.rows[this.selectedRow].id} }, _set: {recording_link: "${this.rows[this.selectedRow].recording_link}", meeting_link: "${this.rows[this.selectedRow].meeting_link}"}) {
            id
          }
        }`,
        update: (store, { data: { update_sessions_scheduletable_by_pk } }) => {
          // this.$apollo.mutate({
          //   mutation: gql`mutation MyMutation($objects: [programs_meetparticipanttable_insert_input!]!) {
          //     delete_programs_meetparticipanttable(where: {schedule_id_id: {_eq: ${update_sessions_scheduletable_by_pk.id}}}) {
          //       affected_rows
          //     }
          //     insert_programs_meetparticipanttable(objects: $objects) {
          //       affected_rows
          //     }
          //   }`,
          //   variables: {
          //     objects: this.rows[this.selectedRow].programs_meetparticipanttables,
          //   },
          //   update: () => {
          //     this.$toast({
          //       component: ToastificationContent,
          //       props: {
          //         title: 'Updated successfully',
          //         icon: 'CheckIcon',
          //         variant: 'success',
          //       },
          //     })
          //     this.$apollo.queries.rows.refetch()
          //     this.mutationLoading = false
          //   },
          // })
          this.$toast({
            component: ToastificationContent,
            props: {
              title: update_sessions_scheduletable_by_pk.id ? 'Updated successfully' : 'Failed to update',
              icon: update_sessions_scheduletable_by_pk.id ? 'CheckIcon' : 'XIcon',
              variant: update_sessions_scheduletable_by_pk.id ? 'success' : 'warning',
            },
          })
          this.$apollo.queries.rows.refetch()
          this.mutationLoading = false
        },
      })
    },
  },
  apollo: {
    program: {
      query() {
        return gql`
        {
          programs_basicinfo_by_pk(id: "${this.$route.params.id}"){
            title
            organization_id_id
          }
        }`
      },
      update: data => data.programs_basicinfo_by_pk,
    },
    rows: {
      query() {
        return gql`
        query rows{
          sessions_scheduletable(
            order_by: {id: desc},
            where: {
              _or: [
                {
                  program_participant_id: {_eq: ${this.$route.params.aid}}
                },
                {
                  sessions_participanttables: {
                    program_participant_id: {_eq: ${this.$route.params.aid}}
                  }
                }
              ]
            },
            distinct_on: id
          ) {
            id
            begin_time
            end_time
            description
            type
            title
            meeting_link
            recording_link
            sessions_participanttables(
              where: {
                program_participant_id: {_eq: ${this.$route.params.aid}}
              },
              order_by: {id: asc}
            ) {
              id
              sessions_slots_tables {
                is_accepted
                id
                begindatetimestamp
                enddatetimestamp
              }
            }
            count_all_participanttables: sessions_participanttables_aggregate {
              aggregate {
                count(columns: id)
              }
            }
            count_rated_participanttables: sessions_participanttables_aggregate(where: { ratings: { _is_null: false } }) {
              aggregate {
                count
                avg {
                  ratings
                }
              }
            }
          }
        }`
      },
      update: data => data.sessions_scheduletable,
    },
    incubators: {
      query() {
        return gql`{
          users_customuser(distinct_on: id, where: {users_associationtables: {organization_id: {_eq: ${this.program.organization_id_id}}}}) {
            id
            full_name
          }
        }`
      },
      update: data => data.users_customuser,
    },
    bookedslots: {
      query() {
        const tempdate = new Date(this.slotdate)
        return gql`
        query bookedSlots{
          sessions_slots_table(
            where: {
              ${getUserData().associatedOrgDetails.length > 0 ? `
                startup_organization_id: {_eq: ${getUserData().associatedOrgDetails[0].organization_id}}
              ` : `
                student_user_id: {_eq: ${getUserData().id}}
              `}
              begindatetimestamp: {_gte: "${(new Date(this.slotdate).toLocaleString('en-US', { timeZone: getUserData().timezone.abbr }))}", _lt: "${(new Date(tempdate.setDate(tempdate.getDate() + 1)).toLocaleString('en-US', { timeZone: getUserData().timezone.abbr }))}"}
            }
          ) {
            begindatetimestamp
            enddatetimestamp
            id
            is_available
          }
        }`
      },
      update: data => data.sessions_slots_table,
    },
    partners: {
      query() {
        return gql`{
          programs_partnerstable(
            where: {
              program_id: {_eq: ${this.$route.params.id}},
              _not: {
                _and: {
                  organization_id_id: {_is_null: true},
                  user_id: {_is_null: true}
                }
              }
            }
          ) {
            id
            users_customuser {
              id
              full_name
            }
            users_organizationtable {
              id
              title
            }
          }
        }`
      },
      update: data => data.programs_partnerstable.map(e => {
        if (e.users_customuser) {
          return ({
            ...e.users_customuser,
            partner_id: e.id,
            type: 'mentor',
          })
        }
        return ({
          ...e.users_organizationtable,
          partner_id: e.id,
          type: 'partner',
        })
      }),
    },
    exercises: {
      query() {
        return gql`{
          programs_assignmenttable(where: {_or: {program_id: {_eq: ${this.$route.params.id}}, programs_operationstable: {program_id: {_eq: ${this.$route.params.id}}}}}) {
            id
            title
          }
        }`
      },
      update: data => data.programs_assignmenttable,
    },
    milestones: {
      query() {
        return gql`{
          programs_progressstagetable(where: {startup_participant_id: {_eq: ${this.selectedStartup && this.selectedStartup.length === 1 ? this.selectedStartup[0] : '0'}}}, order_by: {id: desc}) {
            id
            title
          }
        }`
      },
      update: data => data.programs_progressstagetable,
    },
    choicebookedslots: {
      query() {
        const tempdate = new Date(this.choiceslotdate)
        return gql`
        query choiceBookedSlots{
          sessions_slots_table(
            where: {
              begindatetimestamp: {_gte: "${(new Date(this.choiceslotdate).toLocaleString('en-US', { timeZone: getUserData().timezone.abbr }))}", _lt: "${(new Date(tempdate.setDate(tempdate.getDate() + 1)).toLocaleString('en-US', { timeZone: getUserData().timezone.abbr }))}"},
              _or: [
                {
                  incubator_id: {_eq: ${this.program.organization_id_id}}
                },
                {
                  is_general: {_eq: true}
                }
              ]
              ${this.startuporpartner === 'Incubator' ? `
                incubator_user_id: {_eq: ${this.selectedIncubator.id}}
              ` : ''}
              ${this.startuporpartner === 'Partner' || this.startuporpartner === 'Mentor' ? `
                ${this.selectedPartner.type === 'mentor' ? `
                  mentor_user_id: {_eq: ${this.selectedPartner.id}}
                ` : ''}
                ${this.selectedPartner.type === 'partner' ? `
                  partner_organization_id: {_eq: ${this.selectedPartner.id}}
                ` : ''}
              ` : ''}
            }
          ) {
            begindatetimestamp
            enddatetimestamp
            id
            is_available
          }
        }`
      },
      update: data => data.sessions_slots_table,
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
